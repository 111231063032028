import React from "react";
import { SecondryHeadingVector } from "./Icons";
import {
    GroupChat,
    PromoInvite,
    RealFeedback,
    SocialInteractions,
    ViaCode
  } from "./Icons";

const IncentivePromotions = () => {
    const promotionsCards = [
        {
          icon: <PromoInvite />,
          title: "Promo Invites",
          description: "Incentive helps connect customers and businesses. In Store or In Person included, not just online.",
        },
        {
          icon: <GroupChat />,
          title: "Group Chat to Connect",
          description:
            "Build a solid community around products and services you've found",
        },
        {
          icon: <ViaCode />,
          title: "Invite via Code",
          description:
            "Scale any offer outside of just social media or online channels with code invites",
        },
        {
          icon: <RealFeedback />,
          title: "Get Real Feedback",
          description:
            "Review and discuss products with full trust and transparency before you purchase",
        },
        {
          icon: <SocialInteractions />,
          title: "Social Interactions",
          description:
            "Interact with Others and the Business to get questions answered",
        },
      ];
  return (
    <div className="container container_modified">
      <h2 className="text-black fw-bolder font-xxl text-center mt-4 mb-md-1 py-1 position-relative z-1">
        <span className=" d-inline-block position-absolute center__absolute_content ">
          <SecondryHeadingVector />
        </span>
        Why Incentive Promotions
      </h2>
      <div className="row justify-content-center">
        {promotionsCards.map((obj, index) => (
          <div
            className={`mb-3 mb-md-4 ${
              index === 3 || index === 4 ? "col-sm-6" : "col-sm-6 col-lg-4"
            }`}
            key={index}>
            <div className="tools_card transition_03s bg-white d-flex flex-column align-items-center text-center h-100">
              <span className="cards_circle position-relative">{obj.icon}</span>
              <h3 className="font-lg fw-bold mt-4 mt-md-3 pt-0 pt-md-1 ">
                {obj.title}
              </h3>
              <p className="text-gray font-sm mb-0">{obj.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncentivePromotions;
