import React, {useState, useEffect} from 'react';
import { Col,Container, Form, Row, Button } from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import "./registercode.scss";
//import axios from 'axios';
import axios from '../../backendClient'
import { useDispatch } from 'react-redux';
import { registerConfirm, registerFailure } from '../../redux/userSlice';


const RegisterCode = ()=>  {
   const [code, setCode] = useState("");
   const [phone, setPhone] = useState("");
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();

//    If Safari blocks cookies in produciton use storage Access
//    const storageAccess = async (res) => {
//     // Check for API support
//     if (document.hasStorageAccess) {
//         try {
//             // Request storage access
//             await document.requestStorageAccess();
//             // If we got here, storage access was granted
//             console.log('Storage access granted');
               
//             document.cookie = 'incentive_access_token=' + res.token + ';SameSite=None;Path=/;domain=localhost';
               
//         } catch (err) {
//             // If we got here, storage access was denied
//             console.log('Storage access denied');
//         }
//     } else {
//         // The API is not supported
//         console.log('Storage Access API not supported');
//     }
//    }

   useEffect(() => {
    const phoneVal = location.state?.value; // Access the passed value
    setPhone(phoneVal); }, [location]); 

  const handleCodeSubmit = async e => {
    e.preventDefault();
    try {
        // Make a POST request to the server-side route that handles file upload
        const res = await axios.put('/api/auth/confirm', {phone:phone,code:code}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if(res.data){
            //check if cookies are turned off etc.
            //set them via the client in production
           //storageAccess(res.data);

            // Handle final submission here
           //navigate to Search Page
          dispatch(registerConfirm(res.data));
          const response = await axios.get(`/api/events/hasActiveInvites`,{
            params: {
              phone: phone,
              id: ''}
            });
          if(response.data.length !== 0){
                navigate("/activeinvites"); 
           }else{
            //we should check if there's a state value like singleoffer short title if location.state?.userCode then navigate to listing with that user in cookie
             
                if(location.state?.userCode){
                    const response = await axios.post('/api/events/getEventFromInvite', {affiliateCode: location.state?.userCode}, {headers: {'Content-Type': 'application/json'}});
                    if(response.data){
                        navigate(`/single/${response.data.shortTitle}`);  
                        return; 
                    }
                    
                }
                navigate("/eventlisting");
            }
        
        }
    } catch(err) {
        console.error(err);
        dispatch(registerFailure(err.response.data.message));
    }
    
  };  

  const handleResendCode = () => {
    // Your code to resend the code goes here
  };
  return (
        <Container className='main-container'>
            <Row>
                <Col md={12} className="form-box d-flex align-items-center justify-content-center flex-direction-column order-1 order-md-2">
                        
                    <h2 className="form-title">Confirm Registration</h2>

                        <Form onSubmit={handleCodeSubmit}>
                            <Form.Group className="mb-3">
                            <Form.Label className="form-label">SMS Code</Form.Label>
                            <Form.Control
                                type="text"
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                placeholder="Enter your code"/>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Verify
                            </Button>
                            <div className="py-4 mx-4">
                            <p className='text-center link-style' onClick={handleResendCode}>Resend Code</p>
                            </div>
                        </Form>
                        

                </Col>
            </Row>
        </Container>)
}

export default RegisterCode


