import React from 'react';
import Emptystate from '../../components/Emptystate';


const SuccessPage = () => {



  return (
    <Emptystate title="You have successfully subscribed to Premium" subtitle="Manage your plan in Settings including cancellations"/>
  )
}

export default SuccessPage; 