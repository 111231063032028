import React, { useEffect, useState, useContext } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button  from "react-bootstrap/Button";
//import userImg from "../../../assets/images/dashboard/png/profile_icon2.png";
import CustomButton from "./CustomButton";
import { AppContext } from '../../AppContext';
import axios from '../../backendClient'
import {
  CalenderIcon,
  ClockIcon,
  CodeTick,
  LocationIcon,
  SidebarCrossIcon,
} from "./Icons";
import { useNavigate} from 'react-router-dom';

function Sidebar({ showSidebar, handleClose, data, currentUser }) {
  const [placement, setPlacement] = useState("end");

  const socket = useContext(AppContext);

  const navigate = useNavigate(); 

  const handleEventPage = async(url)=> {
    navigate('/single/'+url); 
  }

  const handleJoin = async(event, host) => {
    if(!socket){
        //use navigate to event listing protected route for sign in 
        navigate('/eventlisting');
    }else{
        //alert('Socket is active!'); //use current user to pass notification for event invite to the host
        // filter out guests who are already "declined, request"
        try{
        const response = await axios.put(`/api/events/changeGuestStatus`,{guestId: currentUser._id, eventId: event._id, inviteStatus: "request"})
        socket.emit("sendNotification",{
            sender: currentUser,
            receiver: host._id, 
            type: 4
        }); }
        catch(e){
          alert(e.response.data.message);
        }
    
        try{
    
            await axios.post('/api/messages/addOfflineNotification',{sender: currentUser, offlineUserId: host._id,  notificationType: 4, notificationMessage: null });
            
        }catch(e){
            console.log('Failed to set unread notification ', e);
        }
    }
    navigate('/event');
    return;    
};

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setPlacement("bottom"); // Change placement for mobile screens
      } else {
        setPlacement("end"); // Default placement for larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Offcanvas
      show={showSidebar}
      onHide={handleClose}
      placement={`${placement}`}
    >
      <Offcanvas.Body className="d-flex gap-3 justify-content-between flex-column">
        <div className="sidebar_top_height">
          <h4 className="mb-3 mb-sm-4 fw-bolder d-flex align-items-center justify-content-between">
            {data?.eventTitle}
            <span className="cursor_pointer" onClick={handleClose}>
              <SidebarCrossIcon />
            </span>
          </h4>
          <div className="membership-details br_10">
            <p className="font-sm text-black d-flex gap-2 align-items-center mb-0">
            <strong>Offer End Date: </strong>  <CalenderIcon /> {data?.eventDate? new Date(data?.eventDate).toUTCString().split(" 00:00:00")[0]: 'Date TBD via Chat'}
            </p>
            <p className="font-sm text-black d-flex gap-2 align-items-center my-1">
            <strong>Time:</strong> <ClockIcon /> {data?.eventTime? new Date(1970, 0, 1, data.eventTime.split(':')[0], data.eventTime.split(':')[1]).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: true}): 'Time TBD via Chat'}
            </p>
            <p className="font-sm text-black d-flex gap-2 align-items-center mb-0">
              <strong>Location:</strong><LocationIcon /> {data?.location || 'Nationwide'}
            </p>
          </div>
          <p className="font-sm text-black fw-bolder d-flex align-items-center gap-2 py-1 py-sm-0 my-3 my-sm-4">
            # Redemptions Left: <span className="fw-normal">{data?.attendees}</span>
          </p>
          <span className="font-sm text-black fw-bolder d-flex align-items-center gap-2 py-2 py-sm-3 px-4 code-tag br_10 mb-3 mb-sm-4">
            <CodeTick />
            {data?.onlineCode ? 'Includes Online Code' : 'In Person Redemption Only'} 
          </span>
          <p className="font-sm text-black fw-bolder mb-1 pt-1">Description:</p>
          <p className="font-sm text-gray mb-0">
           {data?.eventDescription}
          </p>
        </div>
        <div className="position-fixed sidebar_fixed_content py-3 py-sm-4 bg-white">
          <div className="d-flex align-items-center justify-content-between mb-3 pb-1">
            <span className="qualification-tag br_10 font-xs fw-bolder text-blue order-2 order-sm-1">
              {data?.tags[0]}
            </span>
            <div className="d-flex align-items-center gap-2 order-1 order-sm-2">
              <img
                className=""
                style={{ height: "34px", width: "34px", borderRadius: "50%" }}
                src={data?.hostUser.profilePic}
                alt="userImg"
              />
              <p className="font-sm text-black mb-0">{data?.hostUser.userName}</p>
            </div>
          </div>
            <button className={`common_btn w-100`} btnStyle="w-100" onClick={()=> {handleEventPage(data.shortTitle);}} >Event Page</button>
             {(!currentUser || (data?.hostUser._id !== currentUser._id)) ? 
            <button className={`mt-1 common_btn w-100`} btnStyle="w-100" onClick={()=> {handleJoin(data, data.hostUser);}} >Contact via Chat</button> : 
            <CustomButton text="My Offer" btnStyle="w-100" onClick={()=> {navigate('/event');}} /> } 
            <button className={`mt-1 common_btn w-100`} btnStyle="w-100 " onClick={handleClose} >Close</button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Sidebar;
