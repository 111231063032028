import React from "react";
import userImg from "../../components/images/png/user.png"

const BusinessInfo = ({offerObject}) => {
  return (
    <div id="business-info" className="h-100 d-flex flex-column">
      <div className="overflow-hidden br_10">
        <img
          className="w-100 object-fit-cover image-business-Info"
          src={offerObject?.imgEvent}
          alt="business info"
        />
      </div>

      <div className=" px-2 px-sm-3 d-flex flex-column-reverse gap-3 flex-xl-row justify-content-between align-items-center pt-3 pt-sm-4 flex flex-grow-1 ">
        <div className=" d-flex align-items-center gap-2 gap-lg-3 user-details justify-content-between justify-content-xl-start w-100">
          <img  style={{ height: "34px", width: "34px", borderRadius: "50%" }} src={offerObject?.hostUser?.profilePic} alt="profile" />
          <div className=" d-flex d-xl-block align-items-center justify-content-between w-100">
            <h2 className=" mb-0 pb-xl-1 ps-1 ps-xl-0">{offerObject?.hostUser?.userName}</h2>
            <div className="tag d-flex justify-content-center align-items-center mt-xl-2">
              <span className=" d-inline-block">{offerObject?.tags}</span>
            </div>
          </div>
        </div>
        <div className="tag_trust d-flex justify-content-center align-items-center w-100">
          {" "}
          <p className=" mb-0">
            Trust Score: <span>{offerObject?.hostUser?.trustScore}%</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;