import { useRef, useState, useEffect } from "react";
import { HeadingVector, IconButtonArrow  } from "./Icons";
import PrimaryButton from "../../components/PrimaryButton";
import yesIcon from "../../components/images/svg/yes-icon-blue.svg";
import rightImageBlue from "../../components/images/png/wanna-left-blue-img.png"; 
import wannaAdvertise from "../../components/images/png/wanna-advertise.png";
import videoPlay from "../../components/images/svg/play-btn.svg"; 




const WannaAdvertise = () => {

    const videoList = {videoId: "roOrXEJ3Ils"}; 

    const WannaCardsList = [
        {
          title: "Contains optimum dose of brain mushrooms for mental clarity",
        },
        {
          title: "Contains Reishi mushroom potent extract for relaxation and pain",
        },
        {
          title: "Regain memory and think with better clarity on a wide variety of tasks",
        },
        {
          title: "Same ingredients that support reduction of mental cognitive decline",
        },
      ];

      const videoRefs = useRef([]);
      const playerRefs = useRef([]);

      const scrollToSection = (event) => {
        event.preventDefault(); 
        const element = document.getElementById("product-pricing");
        element.scrollIntoView({ behavior: 'smooth' });
      };
   
    



    return (
      <>
        <div id="wanna-advertise" className="position-relative z-1">
          <img
            src={rightImageBlue}
            alt="circle"
            className="position-absolute z-n1 end-0 right_img_blue"
          />
          <div className="left_blue_shadow position-absolute start-0 rounded-pill z-n1"></div>
          <div className="container container_modified">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 col-md-10">
                <h4 className="font-xxl fw-bolder position-relative z-1 mb-0">
                  Ready to Function More Efficently Than Ever? 
                  <span className="wanna_advertise_heading position-absolute mb-0 z-n1 translate-middle-x">
                    <HeadingVector />
                  </span>
                </h4>
                <p className="font-md fw-normal mt-lg-3 mt-2 mb-3 pb-1 pb-lg-0 mb-lg-2 description pe-xl-3">
                  See why thousands of customers attest to the effectiveness of our blend of over 10 potent mushrooms...conveniently in 1 bottle. 
                  Take it morning or night...for focus or recovery. Paired with an otherwise hectic lifestyle our Neuro Mushroom Pro will leave you 
                  handling more while feeling more recovered. 
                </p>
                <div className="position-relative d-lg-none">
                  {/* <span className="position-absolute top-50 start-50 translate-middle">
                    <img
                      src={videoPlay}
                      alt="play"
                      width={40}
                      className="cursor_pointer"
                    />
                  </span>
                  <img
                    src={wannaAdvertise}
                    alt="wanna-advertise"
                    className="w-100 rounded-2 wanna_advertise_img object-fit-cover"
                  /> */}
                       {/* <iframe 
                    id="player-809"
                    title={videoList.videoId}
                    className="w-100 rounded-2 wanna_advertise_img"
                    src={`https://www.youtube.com/embed/${videoList.videoId}?enablejsapi=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    ref={(el) => (videoRefs.current = el)}
                  ></iframe> */}
                    <div className="position-relative girl_image mx-auto">
                        <img
                            src="https://incentiveweb.s3.amazonaws.com/landing/Neuro2.jpeg"
                            alt="product"
                            className="position-relative z-1 w-100"
                        />
                        <div className="linear_clr"></div>
                        </div>
                </div>
                <div className="row pe-xl-4 pt-lg-0 pt-2">
                  {WannaCardsList.map((obj, index) => {
                    return (
                      <div
                        className="col-sm-6 px-2 mt-lg-3 mt-2 pt-1"
                        key={index}
                      >
                        <div className="bg-white cards d-flex align-items-center gap-3">
                          <img src={yesIcon} alt="yes" width={20} height={20} />
                          <p className="font-sm fw-bold text-black mb-0">
                            {obj.title}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="pt-md-2">
                  <PrimaryButton
                    text="I'm Ready to Icrease My Brainpower"
                    btnStyle="justify-content-center mt-4"
                    onclick={scrollToSection}
                    icon={
                      <span className="ps-2 d-inline-block">
                        <IconButtonArrow />
                      </span>
                    }
                  />
                </div>
              </div>
              <div className="col-6 d-none d-lg-inline">
                <div className="position-relative">
                  {/* <span className="position-absolute top-50 start-50 translate-middle"> */}
                  {/* <iframe 
                    id="player-809"
                    title={videoList.videoId}
                    className="w-100 rounded-2 wanna_advertise_img object-fit-cover"
                    src={`https://www.youtube.com/embed/${videoList.videoId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe> */}
                        
                        <div className="position-relative girl_image mx-auto">
                        <img
                            src="https://incentiveweb.s3.amazonaws.com/landing/Neuro2.jpeg"
                            alt="product"
                            className="position-relative z-1 w-100"
                        />
                        <div className="linear_clr"></div>
                        </div>
                
                    {/* <img
                      src={videoPlay}
                      alt="play"
                      width={50}
                      className="cursor_pointer position-absolute top-50 start-50 translate-middle"
                    /> */}
                  {/* </span> */}
                  {/* <img
                    src={wannaAdvertise}
                    alt="wanna-advertise"
                    className="position-absolute top-50 start-50 w-100 rounded-2 wanna_advertise_img object-fit-cover"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default WannaAdvertise;