import React, {useState, useRef, useEffect} from 'react';
//import axios from 'axios';
import axios from '../../backendClient'
import {Col, Container, Form, Row, Button, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faImage } from '@fortawesome/free-solid-svg-icons';
import './register.scss';
import {Link, useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import malePic from '../../assets/malepic.svg';
import femalePic from '../../assets/femalepic.svg';
import businessPic from '../../assets/business.svg'; 
import Customautocomplete from '../event/Customautocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { registerFailure, registerStart, registerSuccess, clearError } from '../../redux/userSlice';
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const [gender, setGender] = useState("Male");
  const [userType, setUserType] = useState("Business"); 
  const [imgSrc, setImgSrc] = useState(businessPic);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState(''); 
  const [city, setCity] = useState('');
  const [image, setImage] = useState(null); 
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const IMG_SIZE = 2 * 2048 * 2048; 
  const {error} = useSelector((state)=> state.user);
  const [show, setShow] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpted, setIsOpted] = useState(false); 
  const recaptchaRef = useRef();
  const location = useLocation(); 
  const {code} = location.state || {}; 
  


  useEffect(() => {
    setShow(false); 
    dispatch(clearError());
  }, [location.state]);


  const validateImg = (e) => {
    
    const file = e.target.files[0];

    if(file.size > IMG_SIZE){
      return alert('Max file size is 8 MB');
    }else{
      setImage(file); 
      setImagePreview(URL.createObjectURL(file));  
    }
  }


  const signup = async (e) => {
      e.preventDefault(); 
      dispatch(registerStart());
      if (!image) return alert('Please Upload Your Profile Photo!');
      const recaptchaValue = recaptchaRef.current.executeAsync();
      if (!recaptchaValue) return alert('Recaptcha Check Failed');
      recaptchaRef.current.reset(); 
      if(!city) return alert('Select your city from the dropdown for accurate search...'); 

      if(!isOpted) return alert('You must agree to sms optin and to the terms of service and good behavior policy.')

      const data = new FormData(); 
      data.append('profilePic',image);
      data.append('userName',name);
      data.append('email',email);
      data.append('phone',phone);
      data.append('city',city);
      data.append('gender',gender);
      data.append('isWhatsapp', isChecked);
      data.append('isOpted', isOpted);
      data.append('userType', userType);  
      data.append('recaptchaValue', recaptchaValue);
      try {
        // Make a POST request to the server-side route that handles file upload
        const res = await axios.post('/api/auth/register', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if(res.data){
          dispatch(registerSuccess());
          navigate("/confirmregister",{ state: { value: phone, userCode: code }});
        }
    } catch(err) {
        console.error(err);
        dispatch(registerFailure(err.response.data.message || err.message));
        setShow(true);
    }
      
  }

  const handleCheck = (e) => {
    setUserType(e.target.value);
    if(e.target.value === "Individual") {
      setImgSrc(malePic);
    } else if (e.target.value === "Business") {
      setImgSrc(businessPic);
    }
  };

  const labelStyle = {
    color: isChecked ? 'green' : 'grey',
    fontSize: '.9em',
    fontStyle: 'italic',
    fontWeight: isChecked ? 'bold' : 'normal'
  };

  const labelOpt = {
    color: isOpted ? 'green' : 'grey',
    fontSize: '.9em',
    fontStyle: 'italic',
    fontWeight: isOpted ? 'bold' : 'normal'
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    
  }

  const handleOptedChange = (e) => {
    setIsOpted(e.target.checked);
    
  }

  return (
    <>
    <Container className='registerContainer'>
      <Row>
      {error && show && <Alert variant="danger" onClose={() => {setShow(false); dispatch(clearError());}} dismissible>{error}</Alert>}
      </Row>
      <Row>
        <Col md={12} className="d-flex align-items-center justify-content-center flex-direction-column mt-3"> 
          <Form style={{width:"100%", maxWidth:950}} onSubmit={signup} >
            <h1 className="text-center">Create Account</h1>
            <div className='signup-profile-pic__container'>
              <img src={imagePreview ? imagePreview: imgSrc} className='signup-profile-pic' alt="" />
              <label htmlFor="image-upload" className="image-upload-label">
                  <FontAwesomeIcon icon={faCirclePlus} />
                  <FontAwesomeIcon icon={faImage} />
              </label>
              <input type="file" id="image-upload" hidden accept="image/png, image/jpeg" onChange={validateImg}/>
            </div>
            <Form.Group className='mb-3'>
                <Form.Label>User Type</Form.Label>
                <div className='d-flex'>
                <Form.Check
                  inline
                  id={'custom-radio'}
                  type="radio"
                  label="Business"
                  value="Business"
                  checked={userType === "Business"}
                  onChange={handleCheck}
                  />
                <Form.Check
                  inline
                  id={'custom-radio'}
                  type="radio"
                  label="Individual"
                  value="Individual"
                  checked={userType === "Individual"}
                  onChange={handleCheck}
                  />
                </div>
              </Form.Group>
            {/* <GenderForm onSelect={handleSelect} /> */}
            <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>{userType==="Individual"? `Profile Name` : `Business Name`}</Form.Label>
                    <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-auto">
                        The name displayed on the platform. 
                      </Tooltip>
                    }>
                    <Form.Control type="text" placeholder={userType ==="Individual" ? `Profile Name`:`Business Name`} onChange ={ e=> setName(e.target.value)} value = {name}/>
                    </OverlayTrigger>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{userType==='Individual'? `Email`: `Business Email`}</Form.Label>
                    <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-auto">
                        Used to send your login code
                      </Tooltip>
                    }>
                    <Form.Control type="text" placeholder="Email" onChange ={ e=> setEmail(e.target.value)} value = {email}/>
                    </OverlayTrigger>
                    <Form.Text className="text-muted">
                    {userType === 'Individual' ? `Your email is always hidden from other users.` : ``}
                    </Form.Text>
                </Form.Group> */}
                <Form.Group controlId="formBasicPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" pattern="(\+?[0-9])?[0-9]{3}-?[0-9]{3}-?[0-9]{4}" placeholder="Phone Number" onChange ={ e=> setPhone(e.target.value)} value = {phone} />
                    <Form.Text className="text-muted">
                    Must be SMS enabled for verification and important notifications only.
                    </Form.Text>
                </Form.Group>
                <Form.Group className='my-3' controlId="formBasicCheckbox">
                  <Form.Check id="checkbox-box-what" type="checkbox" label= {
                    <span style={labelStyle}>
                      {isChecked ? 'Verify via Whatsapp #' : 'I want to verify via Whatsapp not SMS.'}
                    </span>}
                  checked={isChecked} onChange={handleCheckboxChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCity"> 
                     <Form.Label>City</Form.Label>
                                {/* <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-auto">
                        Helps connect you with events/people close to you.
                      </Tooltip>
                    }> */}
                    {/* <Form.Control type="text" placeholder="Enter Your City" onChange ={ e=> setCity(e.target.value)} value = {city} />  */}
                    <Customautocomplete onSelect={setCity} searchType="city"></Customautocomplete>
                    {/* </OverlayTrigger> */}
                </Form.Group>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  size="invisible"/>
                <Form.Group className="mb-3" controlId="agreeText"> 
                <Form.Check id="checkbox-box" type="checkbox" label= {
                    <span style={labelOpt}>
                      {isOpted ? ( <>I agree to honor obligations in line with the  <Link to='/termsofuse'>terms of use </Link> and <Link to='/privacy'>privacy policy</Link> of incentivemeet.com.
                       I opt-in to receive occasional sms messages based on my activity and understand I can turn off sms messages in "Settings" anytime. </> ): (<>I agree to honor obligations in line with the <Link to="/termsofuse">terms of use</Link> and <Link to="/privacy">privacy policy</Link> of incentivemeet.com. I opt-in to receive occasional sms messages based on my activity and understand I can turn off sms messages in "Settings" anytime.</>)}
                    </span>}
                  checked={isOpted} onChange={handleOptedChange} />
                    {/* <Form.Text className="text-muted">
                      I agree not to engage in fraudulent or illegal behavior and to report such behavior to support. I agree to honor obligations in line with the terms of service and privacy policy of incentivemeet.com. I opt-in to receive occasional sms messages based on my 
                      activity and understand I can turn off sms messages. 
                    </Form.Text> */}
                </Form.Group>
                <Button variant="primary" type="submit">
                    1-Click Register
                </Button>
                <div className='py-4'>
                  <p className='text-center'>
                      Already have an account? <Link to='/login'>Login</Link>         
                  </p>
                </div>
            </Form>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Register