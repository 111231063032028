import React from 'react'
import './footer.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import { FacebookWhite, InstagramWhite, YouTubeWhite } from '../pages/social/Icons';
import logo from "../components/images/png/footer-logo.png"; 
import backTop from "../components/images/png/back-to-top.png"; 
// function Footer() {
//   return (
//   <footer>
//     <Container fluid>
//     <Row>
//         <Col className="text-center mt-2">
//          a Rshield production
//         </Col>
//       </Row>
//       <Row>
//         <Col className="text-center py-1">
//           &copy; {new Date().getFullYear()} Red Shield Technology Inc.
//         </Col>
//       </Row>
//       <Row>
//         <Col className="text-center py-1">
//         <LinkContainer to="/privacy"><a href="#" className="text-decoration-none">Privacy Policy</a></LinkContainer>&nbsp; 
//         <LinkContainer to="/termsofuse"><a href="#" className="text-decoration-none">Terms Of Use</a></LinkContainer>
//         </Col>
//       </Row>
//     </Container>
//   </footer>
//   )
// }
const Footer = () => {
  const currentYear = new Date().getFullYear();

  const FooterNewLinksList = [
    {
      link: "Privacy",
      url: "/privacy",
    },
    {
      link: "Terms of Use",
      url: "/termsofuse"}
  ];

  const FooterNewSocialIcons = [
    {
      icon: <FacebookWhite />,
      url: "https://www.facebook.com/@incentiveMeet",
    },
    { icon: <InstagramWhite />, url: "https://www.instagram.com/" },
    { icon: <YouTubeWhite />, url: "https://www.youtube.com/" },
  ];

  // Function to scroll back to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Use smooth scrolling behavior
    });
  };

  return (
    <div className=" mt-4" id="landing-footer">
      <div className="container_modified container pt-3 position-relative">
      <span className="position-relative d-inline-block position-absolute end-0 animate_back_top top-0 translate-middle-y p-2 me-3 me-md-0">
            <img
              onClick={() => scrollToTop()}
              className="w_back_top cursor_pointer position-relative"
              src={backTop}
              alt="back to top"
            />
          </span>
        <div className="d-flex flex-column flex-lg-row gap-3 gap-sm-4 gap-lg-5 gap-lg-0 justify-content-between align-items-center pt-3 mt-1 mt-md-0 pt-md-1 pb-4 border_bottom_footer">
          <span className="logo">
            <Link href="/">
              <img
                className="pb-2 w_footer_logo "
                src={logo}
                alt="footer-logo"
                width={72}
                height={90}
              />
            </Link>
          </span>
          <div className="gap_base gap-sm-4 gap-xl-5 d-flex align-items-center flex-column flex-sm-row ">
            {FooterNewLinksList.map((obj, index) => {
              return ( <LinkContainer to={obj.url}><a href="#" className="text-white  text-decoration-none">{obj.link}</a></LinkContainer>);
            })}
          </div>
          <div className="gap_base d-flex">
            {FooterNewSocialIcons.map((obj, index) => {
              return (
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="social-icons"
                  className="font-sm p-0 nav_hover transition_03s position-relative text-decoration-none hover_social_icon text-white"
                  key={index}
                  href={obj.url}
                >
                  {obj.icon}
                </a>
              );
            })}
          </div>
        </div>
        <div className="py-3 d-flex flex-column flex-md-row gap_base gap-md-0 align-items-center w-100 justify-content-center position-relative">
          <LinkContainer to="/contactUs"><a href="#" className="text-white transition_03s text-white d-inline-block position-md-absolute start-md-0 top-md-50 translate-md-middle-y mb-0 fw-light font-sm mt-1 mt-md-0">Contact Us</a></LinkContainer>
          <p
            className=" mb-0 fw-light font-sm text-white"
            style={{ lineHeight: "150%" }}
          >
            © {currentYear} Red Shield Technology Inc.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
