import React, {useState, useEffect, useContext} from 'react'
import {Col, Card, Button, Row} from 'react-bootstrap';
import './invites.scss';
import axios from '../../backendClient'
import { useSelector } from 'react-redux';
import { AppContext } from '../../AppContext';
import Emptystate from '../../components/Emptystate';

const Invites = ({isBoolean}) => {

  const {currentUser} = useSelector(state => state.user);
  const [events, setEvents] = useState([]);
  const [clickButton, setClickButton] = useState(0);
  const socket = useContext(AppContext); 


 
  const getInviteEvents = async () => {
    if(!isBoolean){
      const response = await axios.get(`/api/events/getUserInvites`);
      setEvents(response.data); 
    }else{
      const response = await axios.get(`/api/events/hasActiveInvites`,{
        params: {
          phone: currentUser.phone,
          id: currentUser._id
        }
      });
      setEvents(response.data); 
      
    }
  
  }

  const attendEvent = async(id, event, status) =>{
   try{ 
    const response = await axios.put(`/api/events/changeGuestStatus`,{guestId: id, eventId: event._id, inviteStatus: status})
    if(!response){
      alert("We couldn't invite the guest");
    }}
    catch(e){
      alert(e.response.data.message); 
    }
    if(status ==="attending"){
      if(currentUser._id !== event.hostUser._id){
        socket.emit("sendNotification",{
          sender: currentUser,
          receiver: event.hostUser._id, 
          type: 2
        }); 

        try{
          await axios.post('/api/messages/addOfflineNotification',{sender: currentUser, offlineUserId: event.hostUser._id,  notificationType: 2, notificationMessage: null });
        }
        catch(e){
          console.log('Failed notification send: ' + e);
        }
      }
       socket.emit("sendNotification",{
        sender: event.hostUser,
        receiver: id, 
        type: 5
       }); 
       try{

        //await axios.post('/api/messages/addOfflineNotification',{sender: currentUser, offlineUserId: event.hostUser._id,  notificationType: 2, notificationMessage: null });
        await axios.post('/api/messages/addOfflineNotification',{sender: event.hostUser, offlineUserId: id,  notificationType: 5, notificationMessage: null });

      }catch(e){
        console.log('Failed to set unread notification ', e);
      }
    }
    if(status ==="request"){
      socket.emit("sendNotification",{
        sender: currentUser,
        receiver: event.hostUser._id, 
        type: 4
       }); 

       try{

        await axios.post('/api/messages/addOfflineNotification',{sender: currentUser, offlineUserId: event.hostUser._id,  notificationType: 4, notificationMessage: null });
        
      }catch(e){
        console.log('Failed to set unread notification ', e);
      }
    }
    
  }

  // useEffect(() => {
  // console.log(events);
  // }, [events]);

  useEffect(() => {
    getInviteEvents(); 
    
  }, [clickButton]);

 
  return (
  <>
  { events && events.length > 0 ?
<Row className="cards-row mb-3">
  {events.map((event, idx) => 
    <Col xs={12} md={12} key={idx} className='mt-3 mb-3 event-card'>
      <Card className='h-100'>
        <Row className="no-gutters align-items-center">
          <Col xs={3} md={2} className="d-flex align-items-center justify-content-center">
            <Card.Img className="img-top" src={event.imgEvent} />
          </Col>
          <Col xs={5} md={8}>
            <Card.Body className="cardBody d-flex flex-column justify-content-between">
              <Card.Text>{event.eventTitle}</Card.Text>
              <Card.Text>{new Date(event.eventDate).toLocaleDateString()}</Card.Text>
              <Card.Text>
                Time: {new Date(1970, 0, 1, event.eventTime.split(':')[0], event.eventTime.split(':')[1]).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: true})}
              </Card.Text>
              <Card.Text>{event.location}</Card.Text>
              <Card.Text>{event.eventCost ? 'Cost '+ event.eventCost : ''}</Card.Text>
              <div className="host-info">
                {event.guest_status === 'request' ? 
                       <>
                       <Card.Img className="host-img" src={event.user.profilePic} /> 
                        <span>{event.user.userName} wants to go!</span></>:
                <>
                <Card.Img className="host-img" src={event.hostUser.profilePic} /> 
                 <span>{event.hostUser.userName}</span></>}
              </div>
            </Card.Body>
          </Col>
          <Col xs={4} md={2} className="d-flex flex-column justify-content-center align-items-center">
            { event.guests[0]?.status === 'request' && event.hostUser._id !== currentUser._id ? <Button variant="danger" style={{width:'100px', textAlign:'center', whiteSpace:'nowrap'}}>Pending</Button> :
           <> <Button variant="success" style={{width:'100px', textAlign:'center', whiteSpace: 'nowrap', marginBottom: '3px'}} onClick={async () => {
                if (!isBoolean) {
                  if(event.guest_status === 'request'){
                    
                    await attendEvent(event.user._id, event, 'attending'); 
                  }
                  else{
                  await attendEvent(currentUser._id, event, 'attending'); 
                  }

                } else{
                  await attendEvent(currentUser._id, event, 'request'); 
                }
              setClickButton(prevCount => prevCount + 1);}}
              >{(!isBoolean? (event.guest_status ==='request'? 'Accept': 'Attend') : 'Join Event')}</Button>
            <Button variant="danger" style={{width:'100px', textAlign:'center', whiteSpace:'nowrap'}} onClick={async () => { 
              if(!isBoolean){
                if(event.guest_status === 'request'){
                  
                  await attendEvent(event.user._id, event, 'declined'); 
                }
                else{
                await attendEvent(currentUser._id, event, 'declined'); 
                }
                
              }else{
              await attendEvent(currentUser._id, event, 'declined'); }
              setClickButton(prevCount => prevCount + 1);
              }
              }>Decline</Button> </>}
          </Col>
        </Row>
      </Card> 
    </Col>
  )}
</Row> : <Emptystate /> } </>

  )
}

export default Invites