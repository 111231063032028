// CustomModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './paidmodal.scss'; 

const Paidmodal = ({ show, handleClose, title, footerText, imgSrc, imgSrc2, children, onNavigate }) => {

const navigate = useNavigate(); 
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <span style={{ background: 'linear-gradient(to right, #FFFFFF, #3b82f6)' }}>Upgrade to Incentive Premium</span>
      <div className="flex justify-between">
    <p className="text-2xl font-medium">
    <ul className="my-modal-list">
            <li>100 Invites per Month</li>
            <li>Invite Premium members</li>
            <li>Impact Trust Scores with Reviews</li>
            <li>Priority Support for Get Paid Events and Promoting Your Events</li>
          </ul>
    </p>
    </div>
       
        <div className="mb-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {imgSrc && <img src={imgSrc} alt="Image 1" style={{ width: '30%', height: 'auto' }} />}
          <span>could attend with</span>
          {imgSrc2 && <img src={imgSrc2} alt="Image 2" style={{ width: '30%', height: 'auto' }} />}
        </div>
        {footerText}
      </Modal.Body>
      <Modal.Footer  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
     
        <Button style={{ background: 'linear-gradient(to right, #FFFFFF, #3b82f6)' }} variant="danger" onClick={onNavigate}>Subscribe</Button>
        {children}
      </Modal.Footer>
    </Modal>
  );
};

    

export default Paidmodal;